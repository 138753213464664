/* src/fonts.css */
@font-face {
    font-family: 'Root';
    src: url('../../public/fonts/PT\ Root\ UI_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RootBold';
    src: url('../../public/fonts/PT\ Root\ UI_Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
